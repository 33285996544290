










import { Prop, Component, Vue } from 'vue-property-decorator'
import GoogleAd from '~components/utils/GoogleAd.vue'

interface AdDef {
  id: string
  dfpSlot: string
  sizes: []
  sizeMapping?: string
}
interface AdSettings {
  sidebar?: AdDef
  mainfeed?: AdDef
}

@Component({
  components: {
    GoogleAd,
  },
})
export default class AdWidget extends Vue {
  @Prop({ type: Boolean, default: false }) readonly inSidebar!: boolean
  @Prop({ type: Object, required: true }) readonly adSettings!: AdSettings

  get adDefinition(): AdDef | undefined {
    if (this.inSidebar) {
      return this.adSettings.sidebar
    }
    return this.adSettings.mainfeed
  }
}
